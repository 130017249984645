import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import excelLogo from '@assets/logo/excel_logo.png';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IDashBoardRenewalRequest, ISummaryDayItem } from '@dataTypes/dashBoardSummary';
import storeInfo from '../../recoil/storeInfo/atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { renewalDashboardByMaster } from '@apis/salesDashboard';
import { SALESDASHBOARD } from './queryKey/queryKey';
import { AxiosHeaders } from 'axios';
import Cookies from 'js-cookie';
import { AUTH } from '@dataTypes/authTypes';
import { format } from 'date-fns';
import errorOrYesModalState from '../../recoil/errorOrYesModal/atom';
import { imgTypes } from '@dataTypes/imgTypes';
import { TbRefresh } from 'react-icons/tb';
import rotateIcon from '@assets/icon/rotate_icon.png';

interface MonthSalesProps {
  month: Date;
  setMonth: React.Dispatch<React.SetStateAction<Date>>;
  excelData: ISummaryDayItem[];
}

const MonthSales = ({ month, setMonth, excelData }: MonthSalesProps) => {
  const authority = Cookies.get('smartlab_authority');
  const queryClient = useQueryClient();
  const compareDate = month.toISOString().slice(0, 7) >= new Date().toISOString().slice(0, 7);
  const storeInfoState = useRecoilValue(storeInfo);
  const setErrorState = useSetRecoilState(errorOrYesModalState);

  const { mutateAsync: renewalDashboardByMasterAsync, isLoading } = useMutation(
    (data: IDashBoardRenewalRequest) => renewalDashboardByMaster(data),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries([SALESDASHBOARD.DASHBOARDSUMMARY]);
        } else {
          setErrorState({
            status: true,
            imgType: imgTypes.ERROR,
            title: '데이터 최신화에 실패했습니다.',
            modalBG: true,
          });
        }
      },
      onError: (error: AxiosHeaders) => {
        setErrorState({
          status: true,
          imgType: imgTypes.ERROR,
          title: error.response.data.message,
          modalBG: true,
        });
      },
    },
  );

  const nextMonthHandler = () => {
    if (compareDate) {
      return;
    }
    const newMonth = new Date(month);
    newMonth.setDate(1); // set to the 1st of the month to avoid end of month issues
    newMonth.setMonth(newMonth.getMonth() + 1);
    setMonth(newMonth);
  };

  const prevMonthHandler = () => {
    const newMonth = new Date(month);
    newMonth.setDate(1); // set to the 1st of the month to avoid end of month issues
    newMonth.setMonth(newMonth.getMonth() - 1);
    setMonth(newMonth);
  };

  const formatDataForExcel = (data: ISummaryDayItem[]) => {
    return data
      .sort((a, b) => +a.date.replaceAll('-', '') - +b.date.replaceAll('-', ''))
      .map((item) => ({
        날짜: item.date,
        카드: item.card.toLocaleString('ko-KR'),
        현금: item.cash.toLocaleString('ko-KR'),
        기타: item.etc.toLocaleString('ko-KR'),
      }));
  };

  const handleDownload = () => {
    // 데이터에 맞는 워크시트 생성
    console.log('excelData: ', excelData);
    // alert('클릭');

    // const worksheet = XLSX.utils.json_to_sheet(formatDataForExcel(excelData));

    const updatedExcelData = formatDataForExcel(excelData);
    updatedExcelData.forEach((row) => {
      row['카드'] = parseInt(row['카드'].replace(/,/g, '')) as unknown as string; // 'string' 타입을 무시
      row['현금'] = parseInt(row['현금'].replace(/,/g, '')) as unknown as string; // 'string' 타입을 무시
      row['기타'] = parseInt(row['기타'].replace(/,/g, '')) as unknown as string; // 'string' 타입을 무시
    });

    const worksheet = XLSX.utils.json_to_sheet(updatedExcelData);

    // 전체 워크시트의 모든 셀을 'General'로 설정
    if (worksheet !== undefined && worksheet['!ref'] !== undefined) {
      // 워크시트의 범위를 얻기
      const range = XLSX.utils.decode_range(worksheet['!ref']);

      // 행(Row)과 열(Column) 범위 내에서 반복
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          // 셀 참조값 얻기
          const cell_ref = XLSX.utils.encode_cell({ r: R, c: C });

          // 셀이 존재하는지 확인
          if (worksheet[cell_ref]) {
            // 숫자 데이터인 경우 서식을 'General'로 설정
            if (typeof worksheet[cell_ref].v === 'number') {
              worksheet[cell_ref].z = 'General'; // 콤마 없이 숫자 표시
            }
          }
        }
      }
    } else {
      alert('excel general error');
    }

    // 워크북 생성 및 워크시트 추가
    const workbook = XLSX.utils.book_new();
    const sheetName = `${month.getMonth() + 1}월`;
    console.log('sheetName: ', sheetName);

    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // 엑셀 파일 생성
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // 파일 저장
    // const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const fileName = `${storeInfoState.currentStore} ${month.getMonth() + 1}월 매출.xlsx`;
    console.log('fileName: ', fileName);

    saveAs(blob, fileName);
  };

  const dashBoardRenewalHandler = () => {
    if (storeInfoState && storeInfoState.currentStoreId) {
      const data = {
        store: storeInfoState.currentStoreId,
        date: format(month, 'yyyyMM'),
      };
      renewalDashboardByMasterAsync(data);
    } else {
      setErrorState({
        status: true,
        imgType: imgTypes.ERROR,
        title: '지점 ID 오류',
        text: '지점 ID가 없습니다.',
        modalBG: true,
      });
    }
  };

  return (
    <div className="month_sales_container">
      <div className="left_section_wrapper">
        <div className="change_month_wrapper">
          <IoIosArrowForward onClick={prevMonthHandler} className="left_arrow" size={'1.5rem'} />
          <span className="month">{month.toISOString().slice(0, 7)}</span>
          <IoIosArrowForward
            onClick={nextMonthHandler}
            className={`right_arrow ${compareDate ? 'disabled' : ''}`}
            size={'1.5rem'}
          />
        </div>
        {authority === AUTH.MASTER && (
          <div onClick={dashBoardRenewalHandler} className="renewal_wrapper">
            <img
              className={`rotate_icon ${isLoading ? 'refresh_loading' : ''}`}
              src={rotateIcon}
              alt="회전 아이콘"
            />
            {/* <TbRefresh
              className={isLoading ? 'loading' : ''}
              size={'1.3rem'} 
              color="rgb(50,50,50)"
              strokeWidth={1.5}
            /> */}
          </div>
        )}
      </div>

      <div className="sales_excel_wrapper" onClick={handleDownload}>
        <img className="logo" src={excelLogo} alt="엑셀 로고" />
        <span className="text">월 매출 다운로드</span>
      </div>
    </div>
  );
};

export default MonthSales;
