import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form, Modal, Offcanvas } from 'react-bootstrap';
import searchIcon from '@assets/icon/search_icon.png';
import SearchUserItem from './SearchUserItem';
import { useGetSearchUser } from '@hooks/grantAuthority/useGetSearchUser';
import Loading from '@components/common/loading/LoadingSpinner';
import { getSearchStoreUser } from '@dataTypes/grantAuthority';
import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo';

interface AddMemberWithAuthTypeModalProps {
  closeAddMemberModal: () => void;
  addMemberModal: boolean;
  showConfirmModal: (user: getSearchStoreUser) => void;
}

const AddMemberWithAuthTypeModal = ({
  closeAddMemberModal,
  addMemberModal,
  showConfirmModal,
}: AddMemberWithAuthTypeModalProps) => {
  const storeInfoState = useRecoilValue(storeInfo);
  const targetRef = useRef<any>(null);
  const [dropdownValue, setDropdownValue] = useState<'phone' | 'loginId'>('phone');
  const [searchInputVal, setSearchInputVal] = useState('');

  const { searchUserList, searchUserLoading, isFetchingNextPage, setSearchTrigger } =
    useGetSearchUser(storeInfoState.currentStoreId, dropdownValue, searchInputVal, targetRef);

  const [errorText, setErrorText] = useState(false);

  const searchInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInputVal(value);
  };

  const searchHandler = () => {
    setErrorText(false);
    setSearchTrigger(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.nativeEvent.isComposing) {
        return;
      }
      searchHandler();
    }
  };

  const handleDropdownSelect = (value: string | null) => {
    if (value === 'phone' || value === 'loginId') {
      setDropdownValue(value);
    }
  };

  return (
    <>
      <Offcanvas
        id="add_Member_modal_wrapper"
        show={addMemberModal}
        onHide={closeAddMemberModal}
        placement="bottom"
      >
        <Offcanvas.Header closeButton className="select_header">
          <Offcanvas.Title>권한 추가</Offcanvas.Title>
          <div className="sub_text">권한 부여할 사용자를 검색 선택해주세요.</div>
        </Offcanvas.Header>
        <Offcanvas.Body className="select_body">
          <div className="dropdown_with_input">
            <DropdownButton
              id="dropdown-basic-button"
              title={`${dropdownValue === 'phone' ? '휴대폰 번호' : '아이디'}`}
              className="select_button"
              onSelect={handleDropdownSelect}
            >
              <Dropdown.Item eventKey="phone">휴대폰 번호</Dropdown.Item>
              <Dropdown.Item eventKey="loginId">아이디</Dropdown.Item>
            </DropdownButton>

            <div className="search_wrapper">
              <Form.Control
                type="text"
                defaultValue={searchInputVal}
                onChange={searchInputHandler}
                placeholder="입력 후 돋보기 버튼을 눌러주세요."
                className="input_section"
                onKeyDown={handleKeyDown}
                autoFocus={true}
              />
              <img
                onClick={searchHandler}
                className="search_icon"
                src={searchIcon}
                alt="검색 아이콘"
              />
            </div>
          </div>
          {errorText && (
            <div className="error_text">
              이름 2자리 이상 또는 휴대폰 번호 7자리 이상을 입력해주세요.
            </div>
          )}

          <div className="top_section_wrapper">
            <div className="name">이름</div>
            <div className="phone">휴대폰 번호</div>
            <div className="member_btn">권한 변경</div>
          </div>

          {!(searchUserLoading || isFetchingNextPage) && searchUserList.length === 0 ? (
            <div className="no_data">데이터가 없습니다.</div>
          ) : (
            <>
              {searchUserList.map((list, index) => (
                <SearchUserItem key={list.id} showConfirmModal={showConfirmModal} user={list} />
              ))}
              {searchUserLoading || (isFetchingNextPage && searchUserList.length !== 0) ? (
                <div>
                  <Loading />
                </div>
              ) : (
                <div ref={targetRef} className="targetRef"></div>
              )}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AddMemberWithAuthTypeModal;
