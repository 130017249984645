import React, { useEffect, useState } from 'react';
import TotalLists from './TotalLists';
import MonthSales from './MonthSales';
import MonthTotalLists from './MonthTotalLists';
import MonthSalesCalendar from './MonthSalesCalendar';
import TotalListsSkeleton from './TotalListsSkeleton';
import { useRecoilValue } from 'recoil';
import storeInfo from '../../recoil/storeInfo/atom';
import { useDashBoardSummaryList } from '@hooks/dashboard/useDashBoardSummaryList';
import { format } from 'date-fns';

const DashBoardRightContainer = () => {
  const storeInfoState = useRecoilValue(storeInfo);
  const [month, setMonth] = useState(() => {
    const date = new Date();
    date.setDate(1);
    return date;
  });
  const { getDashBoardListsLoading, salesData, activeTotalList, thisMonthData, serverError } =
    useDashBoardSummaryList(storeInfoState.currentStoreId, format(month, 'yyyy-MM'));

  useEffect(() => {
    setMonth(new Date());
  }, [storeInfoState]);

  if (serverError) {
    return <div>서버 에러가 발생했습니다. 잠시 후 다시 시도해주세요.</div>;
  }

  if (getDashBoardListsLoading) {
    return (
      <div className="dashboard_right_container">
        <TotalListsSkeleton />
      </div>
    );
  }

  if (!salesData || !activeTotalList || !thisMonthData) {
    return <div>No data available.</div>;
  }

  const { todaySales, yesterdaySales, lastMonthlySales, totalMonthlySales } = thisMonthData;

  const {
    monthlySalesByCard,
    monthlySalesByCash,
    monthlySalesByEtc,
    totalMonthlySales: activeTotalListTotalMonthlySales,
  } = activeTotalList;
  console.log('salesData: ', salesData);

  return (
    <div className="dashboard_right_container">
      <TotalLists
        todayDailySales={todaySales}
        yesterdayDailySales={yesterdaySales}
        totalMonthlySales={totalMonthlySales}
        lastMonthlySales={lastMonthlySales}
      />
      <MonthSales month={month} setMonth={setMonth} excelData={salesData} />
      <MonthTotalLists
        totalMonth={activeTotalListTotalMonthlySales}
        card={monthlySalesByCard}
        cash={monthlySalesByCash}
        etc={monthlySalesByEtc}
      />
      <MonthSalesCalendar currentDate={month} monthSalesData={salesData} />
    </div>
  );
};

export default DashBoardRightContainer;
